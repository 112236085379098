import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';
import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import feathers from '../../../bootstrap/feathers';
import { TerritoryForm } from './TerritoryForm';

interface DetailProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'microsites/territories';
const service = feathers.service(serviceName);
const commonParams = { query: {} };

export const Detail: React.FunctionComponent<
  DetailProps & RouteComponentProps<{ id?: string; micrositeId: string }>
> = (props) => {
  const {
    match: {
      params: { id, micrositeId },
    },
    history,
  } = props;

  const pathPrefix = `/settings/microsites/${micrositeId}/territories`;

  const isEdit = !!id;
  const [state, reload] = useFeathers('get', serviceName, [id, commonParams], true);
  const handleSubmit = async (values) => {
    isEdit
      ? await service.patch(id, values, commonParams)
      : await service.create(values, commonParams);
    history.push(pathPrefix);
  };

  const pageTitle = isEdit ? 'Edit' : 'New';

  if (isEdit && !state.data) {
    return <PreLoader />;
  }

  const initialData = { ...state.data, microsite: micrositeId };
  return (
    <div>
      <PageHeaderRow header={`${pageTitle} Territory`} subheader="Microsite" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        {(!isEdit || state.data) && (
          <TerritoryForm initialValues={initialData} onSubmit={handleSubmit} />
        )}
      </Segment>
    </div>
  );
};
