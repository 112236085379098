import React, { useEffect, useState, useContext } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Checkbox,
  Button,
  Alert,
  Card,
  Heading,
  CardHeader,
  CardBody,
  IconButton,
} from '@chakra-ui/react';

import { useSelector } from 'react-redux';

import { Select, AsyncSelect, MultiValue } from 'chakra-react-select';

import { useForm, useFieldArray } from 'react-hook-form';
import { FaTimes } from 'react-icons/fa';

import type { ModelTypes } from '@inkcloud/icapi-types';
import countries from 'world-countries';

import { countryStateProvinces } from '../../../common';
import feathers from '../../../bootstrap/feathers';

export type initialValuesType = NonNullable<
  Omit<ModelTypes.MicrositeCustomers, 'disabledProducts' | 'orderApprovers'> & {
    disabledProducts: MultiValue<{ label: string; value: string }>;
    orderApprovers: MultiValue<{ label: string; value: string }>;
    territory?: string;
  }
>;

interface ICustomerForm2Props {
  initialValues: initialValuesType;
  onSubmit: (v: initialValuesType) => void;
  errorMessage?: string;
  isSubmitting?: boolean;
}

const countryInclude = ['US', 'CA', 'AU'];

const countryOptions = countries?.map((c) => ({ label: c.name?.official, value: c.cca2 }));

const stateProvinceOptions = (watchCountry: string) =>
  countryStateProvinces(watchCountry)?.map((c: { value: string; text: string }) => ({
    value: c.value,
    label: c.text,
  })) || [];

const mapOptionsToValue = (options: any) =>
  options?.map((o) => ({
    label: o?.key,
    value: o?.product,
  }));

export default function CustomerForm2(props: ICustomerForm2Props) {
  const { initialValues, onSubmit, errorMessage, isSubmitting } = props;

  const authUser = useSelector((state: any) => state.auth.user);
  const tenant = useSelector(
    ({ globals }: { globals: { tenant: { internationalization: { countryCode: string } } } }) =>
      globals.tenant.internationalization
  );

  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
  });

  const [groupOptions, setGroupOptions] = useState<{ label: string; value: string }[]>([]);
  const [managerOptions, setManagerOptions] = useState<{ label: string; value: string }[]>([]);
  const [territoryOptions, setTerritoryOptions] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    const loadGroups = async () => {
      try {
        const res = await feathers.service('/microsites/customer/groups').find({
          query: {
            $np: 1,
            microsite: props.initialValues.microsite,
            $sort: { name: 1 },
            $select: ['_id', 'name'],
          },
        });

        setGroupOptions(res.map((g: any) => ({ label: g.name, value: g._id })));
      } catch (error) {
        console.error(error);
      }
    };

    loadGroups();
  }, []);

  useEffect(() => {
    const loadGroups = async () => {
      try {
        const res = await feathers.service('/microsites/territories').find({
          query: {
            $np: 1,
            microsite: props.initialValues.microsite,
            $sort: { name: 1 },
            $select: ['_id', 'name'],
          },
        });

        setTerritoryOptions(res.map((g: any) => ({ label: g.name, value: g._id })));
      } catch (error) {
        console.error(error);
      }
    };

    loadGroups();
  }, []);

  useEffect(() => {
    const loadManagers = async (id: string) => {
      try {
        const res = await feathers.service('/microsites/customers').find({
          query: {
            $np: 1,
            microsite: props.initialValues.microsite,
            $sort: { name: 1 },
            $select: ['_id', 'firstName', 'lastName'],
            _id: { $ne: id },
          },
        });

        setManagerOptions(
          res?.map((g: any) => ({
            label: `${g?.firstName ?? ''} ${g?.lastName ?? ''}`,
            value: g._id,
          }))
        );
      } catch (error) {
        console.error(error);
      }
    };

    reset({
      ...initialValues,
      address: {
        ...initialValues?.address,
        countryCode: tenant?.countryCode,
      },
    });

    if (authUser?._id) {
      loadManagers(authUser._id);
    }
  }, [authUser?._id]);

  const handleProductOptions: any = async (e: string, callback) => {
    try {
      let res;
      if (e) {
        res = await feathers.service('/microsites/products').find({
          query: {
            microsite: props.initialValues.microsite,
            $sort: { key: 1 },
            $select: ['product', 'key'],
            $or: [{ name: { $LIKE: e } }, { key: { $LIKE: e } }],
          },
        });
      } else {
        // res = await feathers.service("/microsites/products").find({
        //   query: {
        //     microsite: props.initialValues.microsite,
        //     $sort: { key: 1 },
        //     $select: ['product', 'key'],
        //     product: { $in: initialValues?.disabledProducts }
        //   }
        // });
        // setValue("disabledProducts", mapOptionsToValue(res?.data))
      }
      callback(mapOptionsToValue(res?.data));
    } catch (er) {
      console.error(er);
    }
  };

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'metadata',
  });

  const watchGroups = watch('groups');
  const watchManager = watch('manager');
  const watchTerritory = watch('territory');
  const watchOrderApprover = watch('orderApprovers');
  const watchProducts = watch('disabledProducts');
  const watchCountry = watch('address.countryCode') as string;
  const watchOrderApprovalRequired = watch('orderApprovalRequired');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input type="text" {...register('email')} />
        </FormControl>
        <FormControl>
          <FormLabel>Username</FormLabel>
          <Input type="text" {...register('username')} />
        </FormControl>
        <FormControl>
          <FormLabel>Title</FormLabel>
          <Input type="text" {...register('title')} />
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Company Name</FormLabel>
          <Input type="text" {...register('companyName')} />
        </FormControl>
        <FormControl>
          <FormLabel>First Name</FormLabel>
          <Input type="text" {...register('firstName')} />
        </FormControl>
        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input type="text" {...register('lastName')} />
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Country</FormLabel>
          <Select
            {...register('address.countryCode')}
            value={countryOptions?.find((c) => c.value === watchCountry)}
            onChange={(e) => {
              setValue('address.countryCode', e?.value);
              setValue('address.stateProvince', '');
            }}
            placeholder=""
            options={countryOptions}
          />
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Address 1</FormLabel>
          <Input type="text" {...register('address.address1')} />
        </FormControl>
        <FormControl>
          <FormLabel>Address 2</FormLabel>
          <Input type="text" {...register('address.address2')} />
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>City</FormLabel>
          <Input type="text" {...register('address.city')} />
        </FormControl>
        <FormControl>
          <FormLabel>State/Province</FormLabel>
          {countryInclude.includes(watchCountry) ? (
            <Select
              {...register('address.stateProvince')}
              defaultValue={stateProvinceOptions(watchCountry)?.find(
                (c) => c.value === initialValues?.address?.stateProvince
              )}
              onChange={(e) => setValue('address.stateProvince', e?.value)}
              placeholder=""
              options={stateProvinceOptions(watchCountry)}
            />
          ) : (
            <Input type="text" {...register('address.stateProvince')} />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Postal Code</FormLabel>
          <Input type="text" {...register('address.postalCode')} />
        </FormControl>
        <FormControl>
          <FormLabel>Phone</FormLabel>
          <Input type="text" {...register('phone')} />
        </FormControl>
        <FormControl>
          <FormLabel>Fax</FormLabel>
          <Input type="text" {...register('fax')} />
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Groups</FormLabel>
          <Select
            isMulti
            isSearchable
            isClearable
            useBasicStyles
            options={groupOptions}
            value={groupOptions?.filter((g) => watchGroups?.includes(g.value))}
            placeholder=""
            {...register('groups')}
            onChange={(e) => {
              const values = e?.map((v) => v.value) || [];
              setValue('groups', values);
            }}
          />
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Disabled Products</FormLabel>
          <AsyncSelect
            cacheOptions
            isClearable
            isMulti
            useBasicStyles
            value={watchProducts}
            {...register('disabledProducts')}
            onChange={(e) => {
              setValue('disabledProducts', e);
            }}
            placeholder=""
            defaultOptions={!!watchProducts?.length}
            loadOptions={handleProductOptions}
            noOptionsMessage={(v) =>
              !v.inputValue ? 'Start typing product to search...' : 'No options'
            }
          />
          {/* <Select
          isMulti
          isSearchable
          isClearable
          options={[]}
          placeholder=""
          {...register("disabledProducts")}
          onChange={(e) => {
            const values = e?.map(v => v.value) || []
            setValue("disabledProducts", values)
          }}
        /> */}
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Cost Center</FormLabel>
          <Input type="text" {...register('costCenter')} />
        </FormControl>
      </Stack>
      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Manager</FormLabel>
          <Select
            isSearchable
            isClearable
            useBasicStyles
            value={managerOptions?.filter((g) => watchManager?.includes(g?.value))}
            options={managerOptions}
            placeholder=""
            {...register('manager')}
            onChange={(e) => setValue('manager', e?.value)}
          />
        </FormControl>
      </Stack>

      <Stack spacing={7} mb={3} direction="row">
        <FormControl>
          <FormLabel>Territory</FormLabel>
          <Select
            isSearchable
            isClearable
            useBasicStyles
            value={territoryOptions?.filter((g) => watchTerritory?.includes(g?.value))}
            options={territoryOptions}
            placeholder=""
            {...register('territory')}
            onChange={(e) => setValue('territory', e?.value)}
          />
        </FormControl>
      </Stack>

      <Stack spacing={3} mb={3} direction="column">
        <FormControl>
          <Checkbox {...register('isAdmin')}>Admin</Checkbox>
        </FormControl>
        <FormControl>
          <Checkbox {...register('disabled')}>Disabled</Checkbox>
        </FormControl>
        <FormControl>
          <Checkbox {...register('orderApprovalRequired')}>Required Order Approval</Checkbox>

          {watchOrderApprovalRequired && (
            <Select
              isMulti
              isSearchable
              isClearable
              useBasicStyles
              options={managerOptions}
              value={watchOrderApprover}
              placeholder=""
              {...register('orderApprovers')}
              onChange={(e) => {
                setValue('orderApprovers', e);
              }}
            />
          )}
        </FormControl>
      </Stack>
      <Card mb={3}>
        <CardHeader>
          <Heading size="md">Metadata</Heading>
        </CardHeader>
        <CardBody>
          {fields.map((field, index) => (
            <Stack key={field.id} spacing={3} mb={3} direction="row">
              <FormControl>
                <FormLabel>Key</FormLabel>
                <Input type="text" {...register(`metadata.${index}.key`)} />
              </FormControl>
              <FormControl>
                <FormLabel>Value</FormLabel>
                <Input type="text" {...register(`metadata.${index}.value`)} />
              </FormControl>

              <FormControl w="auto">
                <FormLabel> </FormLabel>
                <IconButton
                  aria-label="Remove"
                  onClick={() => remove(index)}
                  icon={<FaTimes />}
                  mt={5}
                />
              </FormControl>
            </Stack>
          ))}
          <Button type="button" onClick={() => append({ key: '', value: '' })}>
            Add
          </Button>
        </CardBody>
      </Card>
      {errorMessage && (
        <Alert mb={3} status="error">
          {errorMessage}
        </Alert>
      )}
      <Button colorScheme="blue" type="submit" isLoading={isSubmitting} isDisabled={isSubmitting}>
        Save
      </Button>
    </form>
  );
}
