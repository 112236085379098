import * as React from 'react';
import {
  Accordion,
  Button,
  Modal,
  Tab,
  Form,
  Header,
  Icon,
  Input,
  Segment,
  Sidebar,
  SidebarProps,
  Message,
} from 'semantic-ui-react';
import Cleave from 'cleave.js/react';
// deploy force 222222
import ReactJson from 'react-json-view';
import brace from 'brace';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/monokai';

import { connect, getIn, Field, FieldArray, FormikProps, FieldProps } from 'formik';

import { ConcatField } from './Concat';
import { TypeFitting } from './TypeFitting';

import { SemanticField } from '../../../common/Form';

interface IFieldSidebarProps extends SidebarProps {
  field: any;
  fields: any;
  index: number;
  errors: any;
  onClose: () => void;
}

const optionsType = [
  { value: 'text', text: 'Text' },
  { value: 'textarea', text: 'Text Area' },
  { value: 'hidden', text: 'Hidden' },
  { value: 'select', text: 'Select' },
  { value: 'multi-select', text: 'Multi Select' },
  { value: 'list', text: 'List' },
  { value: 'image', text: 'Image' },
  { value: 'masked-input', text: 'Masked Input' },
  { value: 'qrcode', text: 'QR Code' },
  { value: 'dynamic-date', text: 'Dynamic Date' },
  { value: 'conditional', text: 'Conditional' },
];

const optionsDateFormat = [
  { value: 'MM/DD/YY', text: 'MM/DD/YY - 00/00/0000' },
  { value: 'DD/MM/YY', text: 'DD/MM/YY - 00/00/0000' },
  { value: 'YY/MM/DD', text: 'YY/MM/DD  - 00/00/0000' },
  { value: 'M/D/YY', text: 'M/D/YY - 0/0/0000' },
  { value: 'D/M/YY', text: 'D/M/YY - 0/0/0000' },
  { value: 'YY/M/D', text: 'YY/M/D - 0000/0/0' },
];

const optionsValidator = [
  { value: 'required', text: 'Required' },
  { value: 'email', text: 'Email' },
];

const optionsFormatter = [
  { value: 'to-lower-case', text: 'To Lower Case' },
  { value: 'to-upper-case', text: 'To Upper Case' },
  { value: 'to-title-case', text: 'To Title Case' },
];

const { useState } = React;

export const FieldSidebar = connect(
  ({ visible, index, field, fields, onChange, onClose, errors }: IFieldSidebarProps) => {
    if (!field) {
      return null;
    }

    const hasAdvanceOptions = !!(
      field?.selectAllowOther ||
      field?.validators?.length ||
      field?.data?.length ||
      field?.concat?.prepend?.enabled ||
      field?.concat?.append?.enabled
    );

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const [isAdvancedVisible, setIsAdvancedVisible] = useState<boolean>(hasAdvanceOptions);

    const [hasDataError, setHasDataError] = useState<boolean>(false);

    return (
      <Sidebar
        as={Segment}
        visible={visible}
        direction={'right'}
        width={'wide'}
        animation={'overlay'}
      >
        <Header block={true} content="Field Options" size={'small'} />
        <Form size="small" action={''} style={{ textAlign: 'left' }}>
          <Form.Field>
            <SemanticField
              name={`fields[${index}].type`}
              fluid
              selection
              search
              component={Form.Dropdown}
              label="Type"
              options={optionsType}
            />
          </Form.Field>

          {field.type === 'dynamic-date' && (
            <>
              <Form.Field>
                <SemanticField
                  name={`fields[${index}].dynamicDateFormat`}
                  fluid
                  selection
                  search
                  component={Form.Dropdown}
                  label="Date Format"
                  options={optionsDateFormat}
                />
              </Form.Field>
              <Form.Field>
                <Field
                  name={`fields[${index}].dynamicDateOffsetDays`}
                  render={({ field: fieldProp }: FieldProps<any>) => (
                    <>
                      <label>Offset Days</label>
                      <Cleave
                        options={{
                          numeral: true,
                          rawValueTrimPrefix: true,
                          numeralPositiveOnly: true,
                          numeralThousandsGroupStyle: 'thousand',
                        }}
                        value={fieldProp?.value}
                        onChange={(e) =>
                          fieldProp.onChange({
                            target: {
                              value: e.target.rawValue,
                              name: `fields[${index}].dynamicDateOffsetDays`,
                            },
                          })
                        }
                      />
                    </>
                  )}
                />
              </Form.Field>
            </>
          )}

          {field.type === 'conditional' && (
            <>
              <label>When </label>
              <SemanticField
                // fluid
                selection
                search
                clearable
                name={`fields[${index}].conditionField`}
                placeholder="Select field..."
                component={Form.Dropdown}
                options={fields?.reduce(
                  (acc, cur) =>
                    cur?.key && cur?.label && cur.type !== 'hidden'
                      ? acc?.push({ value: cur.key, text: cur.label }) && acc
                      : acc,
                  []
                )}
              />
              <label>has a value of </label>
              <SemanticField
                name={`fields[${index}].conditionValue`}
                placeholder="Value..."
                component={Form.Input}
              />
              <label>,then this field will be hidden</label>

              <Form.Field>
                <SemanticField
                  name={`fields[${index}].conditionalTrueTemplate`}
                  fluid
                  selection
                  search
                  component={Form.TextArea}
                  label="Conditional True Template"
                  options={optionsDateFormat}
                />
              </Form.Field>
              <Form.Field>
                <SemanticField
                  name={`fields[${index}].conditionalFalseTemplate`}
                  fluid
                  selection
                  search
                  component={Form.TextArea}
                  label="Conditional False Template"
                  options={optionsDateFormat}
                />
              </Form.Field>
            </>
          )}

          <Form.Field>
            <SemanticField name={`fields[${index}].key`} fluid component={Form.Input} label="Key" />
            {errors?.fields?.[index]?.key && (
              <Message negative size="tiny" content={errors?.fields?.[index]?.key} />
            )}
          </Form.Field>

          <Form.Field>
            <SemanticField
              name={`fields[${index}].label`}
              fluid
              component={Form.TextArea}
              label="Label"
            />
            {errors?.fields?.[index]?.label && (
              <Message negative size="tiny" content={errors?.fields?.[index]?.label} />
            )}
          </Form.Field>
          <Form.Field>
            <SemanticField
              name={`fields[${index}].placeholder`}
              fluid
              component={Form.Input}
              label="Placeholder"
            />
          </Form.Field>

          <Form.Field>
            <SemanticField
              name={`fields[${index}].description`}
              fluid
              component={Form.TextArea}
              label="Description"
            />
          </Form.Field>

          <Accordion>
            <Accordion.Title
              active={isAdvancedVisible}
              index={0}
              onClick={() => setIsAdvancedVisible(!isAdvancedVisible)}
            >
              <Icon name="dropdown" />
              Advanced Options
            </Accordion.Title>
            <Accordion.Content active={isAdvancedVisible}>
              Advanced otpions in here
              {(field.type === 'select' || field.type === 'multi-select') && (
                <Form.Field>
                  <SemanticField
                    name={`fields[${index}].selectAllowOther`}
                    fluid
                    component={Form.Checkbox}
                    label="Allow other option"
                  />
                </Form.Field>
              )}
              {field.type === 'multi-select' && (
                <Form.Field>
                  <SemanticField
                    name={`fields[${index}].multiSelectSeperator`}
                    fluid
                    component={Form.Input}
                    label="Multi select separator"
                  />
                </Form.Field>
              )}
              {field.type !== 'image' && field.type !== 'qrcode' && (
                <TypeFitting field={field} index={index} />
              )}
              {(field.type === 'text' ||
                field.type === 'textarea' ||
                field.type === 'masked-input') && (
                <Form.Field>
                  <SemanticField
                    name={`fields[${index}].maxChars`}
                    fluid
                    component={Form.Input}
                    label="Character limit"
                  />
                </Form.Field>
              )}
              {(field.type === 'text' ||
                field.type === 'textarea' ||
                field.type === 'masked-input') && (
                <Form.Field>
                  <SemanticField
                    name={`fields[${index}].minChars`}
                    fluid
                    component={Form.Input}
                    label="Min Characters"
                  />
                </Form.Field>
              )}
              <Form.Field>
                <SemanticField
                  name={`fields[${index}].validators`}
                  fluid
                  selection
                  search
                  multiple
                  component={Form.Dropdown}
                  label="Validators"
                  options={optionsValidator}
                />
              </Form.Field>
              <Form.Field>
                <SemanticField
                  name={`fields[${index}].formatter`}
                  fluid
                  selection
                  search
                  component={Form.Dropdown}
                  label="Formatter"
                  options={optionsFormatter}
                />
              </Form.Field>
              <Form.Field>
                {(field.type === 'text' ||
                  field.type === 'select' ||
                  field.type === 'list' ||
                  field.type === 'masked-input') && (
                  <>
                    <ConcatField type="prepend" field={field} onChange={onChange} index={index} />

                    <ConcatField type="append" field={field} onChange={onChange} index={index} />
                  </>
                )}

                <Form.Field>
                  <SemanticField
                    name={`fields[${index}].isReadOnly`}
                    fluid
                    component={Form.Checkbox}
                    label="Read Only"
                  />
                </Form.Field>

                {(field.type === 'select' ||
                  field.type === 'list' ||
                  field.type === 'multi-select') && (
                  <Button
                    type="button"
                    size="tiny"
                    content="Data"
                    onClick={() => setIsModalOpen(true)}
                  />
                )}
                {(field.type === 'hidden' || field.type === 'list') && (
                  <>
                    <Form.Field mt={4}>
                      <SemanticField
                        name={`fields[${index}].defaultValue`}
                        placeholder="Default Value..."
                        component={Form.Input}
                      />
                    </Form.Field>

                    {field.type === 'hidden' && (
                      <Form.Field>
                        <SemanticField
                          fluid
                          selection
                          search
                          clearable
                          name={`fields[${index}].clearIfFieldIsEmpty`}
                          placeholder="Clear if field is empty..."
                          component={Form.Dropdown}
                          options={fields?.reduce(
                            (acc, cur) =>
                              cur?.key && cur?.label && cur.type !== 'hidden'
                                ? acc?.push({ value: cur.key, text: cur.label }) && acc
                                : acc,
                            []
                          )}
                        />
                      </Form.Field>
                    )}
                  </>
                )}

                <Form.Field>
                  <SemanticField
                    name={`fields[${index}].isConditionallyHidden`}
                    fluid
                    component={Form.Checkbox}
                    label="Conditionally Hide"
                  />
                </Form.Field>

                {field.isConditionallyHidden && (
                  <>
                    <label>When </label>
                    <SemanticField
                      // fluid
                      selection
                      search
                      clearable
                      name={`fields[${index}].conditionallyHiddenField`}
                      placeholder="Select field..."
                      component={Form.Dropdown}
                      options={fields?.reduce(
                        (acc, cur) =>
                          cur?.key && cur?.label && cur.type !== 'hidden'
                            ? acc?.push({ value: cur.key, text: cur.label }) && acc
                            : acc,
                        []
                      )}
                    />
                    <label>has a value of </label>
                    <SemanticField
                      name={`fields[${index}].conditionallyHiddenValue`}
                      placeholder="Value..."
                      component={Form.Input}
                    />
                    <label>,then this field will be hidden</label>
                  </>
                )}

                <Form.Field>
                  <SemanticField
                    name={`fields[${index}].whenEmptyHideTableRow`}
                    fluid
                    component={Form.Checkbox}
                    label="Remove Table Row if Empty"
                  />
                </Form.Field>

                {field.whenEmptyHideTableRow && (
                  <>
                    <label>If any of these fields are empty, row will be removed</label>
                    <SemanticField
                      // fluid
                      selection
                      search
                      clearable
                      multiple
                      name={`fields[${index}].whenEmptyHideTableRowOtherFields`}
                      placeholder="Select field..."
                      component={Form.Dropdown}
                      options={fields?.reduce(
                        (acc, cur) =>
                          cur?.key && cur?.label && cur.type !== 'hidden'
                            ? acc?.push({ value: cur.key, text: cur.label }) && acc
                            : acc,
                        []
                      )}
                    />
                  </>
                )}

                <Form.Field>
                  <SemanticField
                    name={`fields[${index}].splitOnCharEnabled`}
                    fluid
                    component={Form.Checkbox}
                    label="Split on character"
                  />
                </Form.Field>
                {field.splitOnCharEnabled && (
                  <SemanticField
                    name={`fields[${index}].splitOnChar`}
                    placeholder="Value..."
                    component={Form.Input}
                    label="Character"
                  />
                )}

                {field.type === 'textarea' && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <SemanticField
                        name={`fields.${index}.maxChars`}
                        fluid
                        component={Form.Input}
                        label="Maximum characters"
                      />
                    </Form.Field>
                  </Form.Group>
                )}

                {(field.type === 'image' || field.type === 'qrcode') && (
                  <React.Fragment>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <SemanticField
                          name={`fields.[${index}].imageOptions.width`}
                          label="Image Width"
                          component={Form.Input}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <SemanticField
                          name={`fields.[${index}].imageOptions.height`}
                          label="Image Height"
                          component={Form.Input}
                        />
                      </Form.Field>
                    </Form.Group>
                  </React.Fragment>
                )}
              </Form.Field>
              {isModalOpen && (
                <Modal
                  closeIcon={!hasDataError}
                  closeOnEscape={false}
                  size="small"
                  open={isModalOpen}
                  onClose={() => !hasDataError && setIsModalOpen(false)}
                >
                  <Modal.Header>Data Lookup</Modal.Header>
                  <Modal.Content>
                    <Button
                      disabled={hasDataError}
                      type="button"
                      size="mini"
                      fluid
                      primary
                      content="Done Editing"
                      onClick={() => setIsModalOpen(false)}
                    />
                    {hasDataError ? (
                      <Message
                        size="mini"
                        error
                        content="Please check your data. If your data starts with 0 please wrap it in a double quote."
                      />
                    ) : (
                      <Message
                        size="mini"
                        info
                        content="Remember to press save after closing this modal"
                      />
                    )}

                    {isModalOpen && (
                      <Tab
                        panes={[
                          {
                            menuItem: 'Structured',
                            render: () => (
                              <Tab.Pane>
                                <Field
                                  name={`fields[${index}].data`}
                                  render={({ field: fieldProp }: FieldProps<any>) => (
                                    <>
                                      <ReactJson
                                        src={field?.data || []}
                                        indentWidth={2}
                                        onEdit={(data) =>
                                          fieldProp.onChange({
                                            target: {
                                              value: data.updated_src,
                                              name: fieldProp.name,
                                            },
                                          })
                                        }
                                        onAdd={() => true}
                                        onDelete={() => true}
                                      />
                                    </>
                                  )}
                                />
                              </Tab.Pane>
                            ),
                          },
                          {
                            menuItem: 'Raw',
                            render: () => (
                              <Tab.Pane>
                                <Field
                                  name={`fields[${index}].data`}
                                  render={({ field: fieldProp }: FieldProps<any>) => (
                                    <>
                                      <AceEditor
                                        mode="json"
                                        theme="monokai"
                                        // onChange={(data) => {
                                        //   console.log('raw data', data)
                                        //   // console.log('parse data', JSON.parse(data))
                                        //   // fieldProp.onChange({ target: { value: JSON.parse(data), name: fieldProp.name } })
                                        // }}
                                        focus={hasDataError}
                                        onBlur={(e, code) => {
                                          const anno = code?.session?.$annotations;
                                          // console.log('code', code)

                                          const data = code?.getValue();
                                          const hasError = anno?.some((a) => a.type === 'error');

                                          setHasDataError(hasError);

                                          if (!hasError) {
                                            try {
                                              fieldProp.onChange({
                                                target: {
                                                  value: JSON.parse(data),
                                                  name: fieldProp.name,
                                                },
                                              });
                                            } catch (err) {
                                              setHasDataError(true);
                                            }
                                          }
                                        }}
                                        name="data-editor"
                                        editorProps={{ $blockScrolling: true }}
                                        value={JSON.stringify(field?.data || [], null, 2)}
                                        width={'100%'}
                                        showGutter
                                      />
                                    </>
                                  )}
                                />
                              </Tab.Pane>
                            ),
                          },
                        ]}
                      />
                    )}
                  </Modal.Content>
                </Modal>
              )}
            </Accordion.Content>
          </Accordion>
          <br />
          <Button primary content="Save" onClick={() => onClose()} type="button" />
          <Button content="Cancel" onClick={onClose} type="button" />
        </Form>
      </Sidebar>
    );
  }
);
