import * as React from 'react';
import { Button, Form, Input, Dropdown } from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SemanticField } from '../../../common/Form';
import { usStateProvinces } from '../../../common';
import { EntityDropdownWrapper2 } from '../../EntityDropdown';

interface TerritoryFormProps {
  initialValues: any;
  onSubmit: Function;
  isSubmitting?: boolean;
}

export const TerritoryForm: React.FC<TerritoryFormProps> = (props) => {
  const CustomerContactSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  const customersDropdown = EntityDropdownWrapper2(
    '/microsites/customers',
    {
      query: {
        $np: 1,
        microsite: props.initialValues.microsite,
        $sort: { name: 1 },
        $select: ['_id', 'firstName', 'lastName'],
      },
    },
    {
      resultMap: (row) => ({
        value: row._id,
        text: `${row?.firstName ?? ''} ${row?.lastName ?? ''}`,
      }),
    }
  );

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={CustomerContactSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
        } = props;

        return (
          <FormikForm className={`ui form ${isSubmitting && 'loading'}`}>
            <Form.Group widths="equal">
              <Form.Field>
                <SemanticField name={'name'} label="Name" component={Form.Input} />
                <ErrorMessage component="div" name="name" />
              </Form.Field>
            </Form.Group>

            <div className="field">
              <label>Manager</label>
              <SemanticField
                clearable
                fluid
                selection
                name="manager"
                placeholder="Manager..."
                component={customersDropdown}
              />
            </div>

            <Button primary type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
